const parallaxItems = document.querySelectorAll('[data-parallax]');

if (parallaxItems.length > 0) {
  const mm = gsap.matchMedia();

  const moveObjects = (event) => {
    mm.add('(min-width: 1199px)', () => {
      const offsetX = (event.clientX - window.innerWidth / 3) * 0.02;
      const offsetY = (event.clientY - window.innerHeight / 1) * 0.02;

      parallaxItems.forEach((object, index) => {
        const directionX = index % 3 === 0 ? 2 : -2;
        const directionY = index % 3 === 0 ? -2 : 2;
        const objectOffsetX = offsetX * directionX * (index + 2) * 0.2;
        const objectOffsetY = offsetY * directionY * (index + 2) * 0.2;
        gsap.to(object, { x: objectOffsetX, y: objectOffsetY, duration: 0.9 });
      });
    });
  };

  document.addEventListener('mousemove', moveObjects);
}
