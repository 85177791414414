const rangeSliderElements = document.querySelectorAll('.range-slider-input');

rangeSliderElements.forEach((el) => {
  const rangeFrom = +el.getAttribute('data-from');
  const rangeMinNum = +el.getAttribute('data-min');
  const rangeMaxNum = +el.getAttribute('data-max');
  const parentEle = el.closest('.range-slider');
  const rangeValueInput = parentEle.querySelector('.range-slider__value');

  noUiSlider.create(el, {
    start: [rangeFrom],
    connect: 'upper',
    step: 1,
    tooltips: wNumb({ decimals: 0 }),
    range: { min: rangeMinNum, max: rangeMaxNum },
  });

  el.noUiSlider.on('update', (values, handle) => {
    rangeValueInput.value = parseFloat(values[handle]).toFixed(0);
  });
});
